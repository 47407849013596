.contact-form {
    max-width: 900px;
    margin: auto;
    padding: 2rem;
  }
  
.contact-form h2 {
    text-align: center;
    font-size: 2rem;
    color: #2b3a4b;
}
  
.contact-form p {
    text-align: center;
    font-size: 1rem;
    color: #444;
    margin-bottom: 2rem;
}
  
.form-grid {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}
  
.form-row {
    display: flex;
    gap: 4rem;
    flex-wrap: wrap;
}
  
.form-row > div, .form-single {
    flex: 1;
    min-width: 250px;
}


  
label {
    display: block;
    font-weight: 600;
    margin-bottom: 0.5rem;
}
  
input[type="text"],
input[type="email"],
input[type="tel"],
select,
textarea {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ccc;
    border-radius: 2px;
    font-size: 1rem;
    background-color: #f9f9f9;
}

.required-asterisk {
    color: red;
}
  
textarea {
   resize: vertical;
}
  
.checkbox-row {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1rem;
    line-height: 1.4;
}

  
.submit-btn {
    background-color: white;
    box-shadow: 0 4px 8px rgba(61, 169, 252, 0.2);
    color: #3da9fc;
    font-size: 1rem;
    font-weight: 600;
    padding: 0.75rem 2rem;
    border: 2px solid #3da9fc;
    border-radius: 1px;
    cursor: pointer;
    align-self: center;
    margin-top: 1rem;
    transition: all 0.2s ease;
}
  
.submit-btn:hover {
    background-color: #3291e6;
    border: 2px solid #3291e6;
    color: white;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
}

