.page {
    overflow: hidden;
}


.about-front {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
    text-align: center;
    margin-top: 0vh;
    width: 100vw;
    height: 50vh;
    background-color: #1c3d5a;
    color: white;
}

.about-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
    gap: 3rem;
}



.about-description {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 79%;
    flex-wrap: wrap;
}


.about-image img {
    max-width: 500px;

    height: auto;
  }
  
.about-text {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    justify-content: center;
    padding:0;
}
  
.about-text h2 {
    padding:0;
    margin: 0;
    font-size: 2rem;
    color: #1c3d5a;
}
  
.about-text p {
    padding: 0;
    margin: 0;
    font-size: 1.05rem;
    line-height: 2;
    color: #444f5a;
    margin-top: 1.5rem;
}
