
/* Navbar.css */

.nav-bar-wrapper {
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 30px;
  padding-right: 50px;
  height: 12vh;
  max-width: 100vw;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
  position: sticky;
  top: 0;
  z-index: 1000;
}

.menu-icon {
  display: none;
}

.logo {
  height: 85%;
}

.logo img {
  height: 100%;
  width: auto;
  /* object-fit: contain; */
}

.nav-links {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  margin: 1rem;
  padding: 0;
  z-index: 10000000;
}

.nav-links li a {
  color: #1c3d5a;
  text-decoration: none;
  font-weight: 600;
  font-size: 1rem;
  transition: color 0.2s ease, text-decoration 0.2s ease;
}

.nav-links li a:hover {
  color: #3da9fc;
  text-decoration: underline;
}


@media (max-width: 600px) {
  .nav-bar-wrapper {
    display: flex;
    align-items: center;
  }

  .menu-icon {
    display: flex;
    font-size: 3rem;
    cursor: pointer;
    color: #1c3d5a;
  }

  
  .nav-elements {
    position: absolute; 
    right: 0;
    top: 12vh;
    background-color: #1c3d5a;
    width: 0px;
    transition: all 0.3s ease-in;
    overflow: hidden;
  }

  .nav-elements.active {
    width: 100%;
    max-width: 100%;
    background-color: #1c3d5a;
    box-sizing: border-box;
  }

  .nav-elements.active a{
    color: white;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
  }

  
}

@media (max-width: 768px) {
  .nav-elements ul li:not(:last-child) {
    margin-right: 30px;
  }
}

