.front {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
    text-align: center;
    margin-top: 0vh;
    width: 100vw; /*Full viewport width */
    height: 80vh; /*Full viewport height */
    overflow: hidden; /* Ensures no scrollbars if the video exceeds bounds */
    position: relative;
}

.video-wrapper video{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Centers the video */
  width: 100vw; /* Full width */
  object-fit: cover; /*(Ensures the video covers the entire container */ 
}

.front-text {
    width: 50%;
    padding: 3%;
    background-color: white;
    z-index: 1;
}

.front-text h1 {
  font-size: 2rem;
  font-weight: 700;
  color: #2b3a4b;
  margin-bottom: 0;
  line-height: 1.2;
}

.front-text p {
  font-size: 1.05rem;
  color: #444f5a;
  line-height: 1.6;
  text-align: center;
  margin-bottom: 2rem;
}



.element-containter {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around; 
    width: 100vw;
    overflow: hidden;
}

.element-containter h1 {
  color: #2b3a4b;
}

.description-photo-containter {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 80%;
    align-items: center;
    justify-content: space-around; 
}

.description-containter {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly; 
    width: 500px;
}

.description-containter h1 {
    font-size: 2rem;
    font-weight: 700;
    color: #2b3a4b;
    margin-bottom: 0;
    line-height: 1.2;
}

.description-containter p {
    font-size: 1.05rem;
    color: #444f5a;
    line-height: 1.6;
    text-align: left;
    margin-bottom: 2rem;
}

.description-containter strong {
    font-weight: 700;
    color: #2b3a4b;
}

.description-containter button {
    background-color: white;
    color: #3da9fc;
    border: 2px solid #3da9fc;
    border-radius: 1px;
    padding: 10px 24px;
    margin-top: 0;
    margin-bottom: 2.5rem;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(61, 169, 252, 0.2);
    transition: all 0.2s ease;
}

.description-containter button:hover {
    background-color: #3291e6;
    border: 2px solid #3291e6;
    color: white;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
}

.photo-container {
    max-height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
    width: 600px;
    overflow: hidden;
}

.photo-container img {
  width: 100%;
  height: auto;
  object-fit: contain; /* or 'cover' if you want it to fill the container */
}

.thumbnail-image {
  cursor: pointer;
  max-width: 100%;
  height: auto;
  transition: transform 0.3s ease;
}

.thumbnail-image:hover {
  transform: scale(1.02);
}

.lightbox-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.85);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.lightbox-image {
  max-width: 80%;
  max-height: 80%;
  box-shadow: 0 0 30px rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.lightbox-caption {
  margin-top: 1rem;
  font-size: 1.5rem;
  color: #eee;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}


.services-containter {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.services-containter button {
  max-width: 40%;
  background-color: white;
  color: #3da9fc;
  border: 2px solid #3da9fc;
  border-radius: 1px;
  padding: 10px 24px;
  margin-top: 1.5rem;
  margin-bottom: 2rem;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(61, 169, 252, 0.2);
  transition: all 0.2s ease;
}

.services-containter button:hover {
  background-color: #3291e6;
  border: 2px solid #3291e6;
  color: white;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
}

.icon-text-container {
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around; 
    gap: 1rem;
}

.icon-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
    text-align: center;
    color: #2b3a4b;
    height: auto;
    width: 300px;
    padding-top: 40px;
    padding-bottom: 30px;
    /* border: 1px solid #2b3a4b; */
    transition: color 0.3s ease, border-bottom 0.3s ease;
    border-radius: 12px;
    box-shadow: 0 4px 8px #2b3a4b;
}

.icon-text:hover {
    background-color: #3291e6; /* Change link color on hover */
}

.icon-text:hover .service-icon{
  color: white; /* Change link color on hover */
}

.icon-text:hover h2{
  color: white; /* Change link color on hover */
}

.service-icon {
  font-size: 100px;
  color: #2b3a4b; /* Tailwind blue-500; adjust to match your theme */
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.popup-box {
  background: white;
  padding: 2rem;
  border-radius: 12px;
  text-align: center;
  max-width: 600px;
  width: 90%;
  position: relative;
  margin-top: 2rem;
}

.popup-image {
  max-width: 100%;
  border-radius: 10px;
  object-fit: contain;
}

.popup-text {
  margin-top: 1rem;
  color: #333;
  font-size: 1rem;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 14px;
  font-size: 1.5rem;
  background: none;
  border: none;
  cursor: pointer;
}




.article-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    gap: 30px;
    margin-bottom: 2rem;
}

.article {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    border: 2px dashed #3da9fc;
    justify-content: space-evenly;
}

.article:hover {
    color: white;
    border: 2px solid #3291e6;
    background-color: #3291e6; /* Change link color on hover */
}

.article:hover a {
    color: white;
}

.article h2 {
    margin-bottom: 0;
}

.article h2 a {
    color: #1c3d5a;
    text-decoration: none;
    transition: text-decoration 0.3s ease;
}
  
.article h2 a:hover {
    text-decoration: underline;
}

.article-meta {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap; /* Optional if you want it to break on small screens */
}

.article-container button {
  max-width: 40%;
  background-color: white;
  color: #3da9fc;
  border: 2px solid #3da9fc;
  border-radius: 1px;
  padding: 10px 24px;
  margin-top: 0;
  margin-bottom: 2.5rem;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(61, 169, 252, 0.2);
  transition: all 0.2s ease;
}

.article-container button:hover {
  background-color: #3291e6;
  border: 2px solid #3291e6;
  color: white;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
}




.team-container {
    display: flex;
    gap: 100px;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
}
  
  .team-card {
    width: 220px;
    text-align: center;
    padding: 16px;
    border-radius: 12px;
    background-color: #fff;
    box-shadow: 0 4px 12px rgba(0,0,0,0.06);
  }
  
  .team-photo {
    width: 100%;
    height: auto;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 12px;
  }
  
  .role {
    font-weight: 600;
    color: #555;
    margin-bottom: 8px;
  }
  
  .description {
    list-style: none;
    padding: 0;
    margin-bottom: 12px;
    font-size: 0.9rem;
    color: #666;
  }
  
  .social-icons a {
    margin: 0 6px;
    color: #3da9fc;
    font-size: 1.2rem;
    transition: color 0.2s;
  }
  
  .social-icons a:hover {
    color: #1d76d0;
  }



  .newsletter-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 1px solid #ccc;
    background-color: #1c3d5a;
    color: white;
    border-radius: 4px;
    width: 50vw;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  }

  .newsletter-subscribe {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
    width: 60%;
  }
  
  .newsletter-input {
    flex: 1;
    border: none;
    background: transparent;
    padding: 10px;
    color: black;
    font-size: 1rem;
    outline: none;
    width: 100px;
  }
  
  .newsletter-input::placeholder {
    color: #aaa;
    font-style: italic;
  }
  
  .newsletter-button {
    background-color: #00d6ff;
    color: #000;
    font-weight: 600;
    border: none;
    padding: 10px 16px;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .newsletter-button:hover {
    background-color: #00c0e5;
    color: white;
  }